import React from 'react';

export const SuccessSvg: any = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16Z"
        fill="#0090FF"
      />
      <path
        d="M20.8499 13.9571C21.2404 13.5666 21.2404 12.9334 20.8499 12.5429C20.4594 12.1524 19.8262 12.1524 19.4357 12.5429L14.6429 17.3357L12.5572 15.3358C12.1667 14.9453 11.5335 14.9453 11.143 15.3358C10.7525 15.7263 10.7525 16.3595 11.143 16.75L13.9357 19.457C14.3263 19.8476 14.9594 19.8476 15.35 19.457L20.8499 13.9571Z"
        fill="white"
      />
    </svg>
  );
};

export const ErrorSvg: any = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16Z"
        fill="#D02931"
      />
      <path
        d="M21.2929 12.1357C21.6834 11.7452 21.6834 11.112 21.2929 10.7215L21.2785 10.7071C20.888 10.3166 20.2548 10.3166 19.8643 10.7071L17.4142 13.1572C16.6332 13.9383 15.3668 13.9383 14.5858 13.1572L12.1357 10.7071C11.7452 10.3166 11.112 10.3166 10.7215 10.7071L10.7071 10.7215C10.3166 11.112 10.3166 11.7452 10.7071 12.1357L13.1572 14.5858C13.9383 15.3668 13.9383 16.6332 13.1572 17.4142L10.7071 19.8643C10.3166 20.2548 10.3166 20.888 10.7071 21.2785L10.7215 21.2929C11.112 21.6834 11.7452 21.6834 12.1357 21.2929L14.5858 18.8428C15.3668 18.0617 16.6332 18.0617 17.4142 18.8428L19.8643 21.2929C20.2548 21.6834 20.888 21.6834 21.2785 21.2929L21.2929 21.2785C21.6834 20.888 21.6834 20.2548 21.2929 19.8643L18.8428 17.4142C18.0617 16.6332 18.0617 15.3668 18.8428 14.5858L21.2929 12.1357Z"
        fill="white"
      />
    </svg>
  );
};

export const DeleteSvg: any = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="9.5" y="10.5" width="13" height="1" rx="0.5" stroke="#C2C2C2" />
      <path
        d="M13.5 9C13.5 8.44772 13.9477 8 14.5 8H17.5C18.0523 8 18.5 8.44772 18.5 9V11H13.5V9Z"
        stroke="#C2C2C2"
        strokeWidth="2"
      />
      <path
        d="M11 11H21V21C21 22.1046 20.1046 23 19 23H13C11.8954 23 11 22.1046 11 21V11Z"
        stroke="#C2C2C2"
        strokeWidth="2"
      />
      <rect x="13.5" y="14.5" width="2" height="5" rx="1" fill="#C2C2C2" />
      <rect x="16.5" y="14.5" width="2" height="5" rx="1" fill="#C2C2C2" />
    </svg>
  );
};

export const LockSvg: any = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 9C8 6.79086 9.79086 5 12 5C14.2091 5 16 6.79086 16 9V10H8V9Z" stroke="#C2C2C2" strokeWidth="2" />
      <rect x="6" y="10" width="12" height="10" rx="2" stroke="#C2C2C2" strokeWidth="2" />
      <rect x="11" y="13" width="2" height="4" rx="1" fill="#C2C2C2" />
    </svg>
  );
};

export const ChangeFileSvg: any = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.25195 10C8.02351 8.23409 9.78559 7 11.8359 7C13.8862 7 15.6483 8.2341 16.4199 10M17.25 8V11H14.25M16.748 14C15.9765 15.7659 14.2144 17 12.1641 17C10.1138 17 8.35169 15.7659 7.58013 14M6.75 16L6.75 13L9.75 13"
        stroke="#C2C2C2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ReturnSvg: any = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.25195 10C8.02351 8.23409 9.78559 7 11.8359 7C13.8862 7 15.6483 8.2341 16.4199 10M17.25 8V11H14.25M16.748 14C15.9765 15.7659 14.2144 17 12.1641 17C10.1138 17 8.35169 15.7659 7.58013 14M6.75 16L6.75 13L9.75 13"
        stroke="#D02931"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ViewDocumentSvg: any = () => {
  return (
    <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.9235 7.6C17.9035 2.91 14.1035 0 10.0035 0C5.90348 0 2.10348 2.91 0.0834848 7.6C0.0284215 7.72617 0 7.86234 0 8C0 8.13766 0.0284215 8.27383 0.0834848 8.4C2.10348 13.09 5.90348 16 10.0035 16C14.1035 16 17.9035 13.09 19.9235 8.4C19.9785 8.27383 20.007 8.13766 20.007 8C20.007 7.86234 19.9785 7.72617 19.9235 7.6ZM10.0035 14C6.83348 14 3.83348 11.71 2.10348 8C3.83348 4.29 6.83348 2 10.0035 2C13.1735 2 16.1735 4.29 17.9035 8C16.1735 11.71 13.1735 14 10.0035 14ZM10.0035 4C9.21236 4 8.439 4.2346 7.7812 4.67412C7.12341 5.11365 6.61072 5.73836 6.30797 6.46927C6.00522 7.20017 5.926 8.00444 6.08034 8.78036C6.23468 9.55628 6.61565 10.269 7.17506 10.8284C7.73447 11.3878 8.4472 11.7688 9.22312 11.9231C9.99905 12.0775 10.8033 11.9983 11.5342 11.6955C12.2651 11.3928 12.8898 10.8801 13.3294 10.2223C13.7689 9.56448 14.0035 8.79113 14.0035 8C14.0035 6.93913 13.5821 5.92172 12.8319 5.17157C12.0818 4.42143 11.0644 4 10.0035 4ZM10.0035 10C9.60792 10 9.22124 9.8827 8.89234 9.66294C8.56345 9.44318 8.3071 9.13082 8.15573 8.76537C8.00435 8.39991 7.96474 7.99778 8.04191 7.60982C8.11908 7.22186 8.30957 6.86549 8.58927 6.58579C8.86898 6.30608 9.22534 6.1156 9.6133 6.03843C10.0013 5.96126 10.4034 6.00087 10.7689 6.15224C11.1343 6.30362 11.4467 6.55996 11.6664 6.88886C11.8862 7.21776 12.0035 7.60444 12.0035 8C12.0035 8.53043 11.7928 9.03914 11.4177 9.41421C11.0426 9.78929 10.5339 10 10.0035 10Z"
        fill="#C2C2C2"
      />
    </svg>
  );
};

export const CancelSvg: any = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="8" fill="#4AAEF2" />
      <path
        d="M11.0712 4.75774C10.6806 4.36721 10.0475 4.36721 9.65695 4.75774L7.87145 6.54323L6.12145 4.79323C5.73093 4.40271 5.09776 4.40271 4.70724 4.79323C4.31671 5.18375 4.31671 5.81692 4.70724 6.20744L6.45724 7.95744L4.7072 9.70748C4.31668 10.098 4.31668 10.7312 4.7072 11.1217C5.09772 11.5122 5.73089 11.5122 6.12141 11.1217L7.87145 9.37166L9.65699 11.1572C10.0475 11.5477 10.6807 11.5477 11.0712 11.1572C11.4617 10.7667 11.4617 10.1335 11.0712 9.74298L9.28567 7.95744L11.0712 6.17195C11.4617 5.78143 11.4617 5.14826 11.0712 4.75774Z"
        fill="white"
      />
    </svg>
  );
};
