import React, { createContext, useCallback, useState, useContext } from 'react';

//INTERFACES
import { AuthContextData, AuthState } from '../interfaces';

//SERVICE
import FeatureFlagService from '../services/FeatureFlag';
import AuthServices from '../services/AuthService';
import logger from '../utils/logger.utils';

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

type AuthProviderProps = {
  children: React.ReactNode;
};

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@BariConsig:token');
    const user = localStorage.getItem('@BariConsig:user');
    const flag = localStorage.getItem('@BariConsig:flag');

    try {
      if (token && user) {
        const parsedUser = JSON.parse(user);
        const parsedFlag = flag ? JSON.parse(flag) : {};
        return { token, user: parsedUser, flag: parsedFlag };
      }
    } catch (error) {
      logger.error('Error parsing the values of user and flag');
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password, originType }) => {
    const response = await AuthServices.login({
      email,
      password,
      originType,
    });

    const { token } = response.data;

    const user = response.data;

    localStorage.setItem('@BariConsig:token', token), localStorage.setItem('@BariConsig:user', JSON.stringify(user));
    const flag = await FeatureFlagService.fetchFlag();

    if (flag.data) {
      localStorage.setItem('@BariConsig:flag', JSON.stringify(flag.data));
    }

    setData({ token, user, flag });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@BariConsig:token');
    localStorage.removeItem('@BariConsig:user');
    localStorage.removeItem('@BariConsig:flag');

    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut, flag: data.flag }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
